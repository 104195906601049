<template>
  <div class="container-sm my-2">
    <div class="header">
      <el-page-header @back="goBack" content="編輯個人簡介/履歷" title="回首頁"></el-page-header>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-start d-flex"> 
          <div class="editArea" >個人簡介</div>
          <el-button  class="ms-auto" link  @click="editing_profile_click()" v-if="editing_profile == false" > <i class="fa-solid fa-pen" ></i>
          </el-button>
          <el-button class="  ms-auto" link  @click="editing_profile_end_click()" v-else ><i class="fa-solid fa-floppy-disk"></i></el-button>

        </div>
        
        <div v-if="editing_profile==true">
          <ckeditor id="ckeditor" :editor="editor" v-model="content" :config="editorConfig"  ></ckeditor>
        </div>
        <div v-else>
          <p v-if="content==''" class="text-start" @click="editing_profile_click()" >填寫個人簡介讓大家認識你吧 ~</p>
          <p v-html="content"  class="text-start"></p>
        </div>
        
        <div class="text-start"> 
          <div  class="editArea" v-if="editing_LearningResume == false">學習履歷</div>

          
        </div>
        <div>
          <ul class="list-group list-group-flush">
          <li v-for="i in learningResumes" :key="i" class="list-group-item px-0"  >
            <div class="d-flex align-items-start">
              <el-switch
                  @change="handleSwitchChange(i)"
                v-model="i.visible"
                size="small"
                class="me-2"
                :disabled="i.title==''"
                inline-prompt
                :active-icon="Check"
                :inactive-icon="Close"

              />  
              <div class="d-flex flex-column align-items-start mt-1">
                <span  v-if="i.title!=''">{{i.title}}</span>
                <span v-else class="text-secondary" >請輸入名稱</span>
                <div class="learningResume_content">
                  {{ i.content }}
                </div>
              </div>
              <div  class="ms-auto">
                <el-button link  @click="open_editLearningResume(i)">
                <i class="fa-solid fa-pen" ></i>
              </el-button>
              <el-button link class="ms-1"  @click="deleteLearningResume(i.id)">
                <i class="fa-regular fa-trash-can"></i>
              </el-button>
              </div>
            </div>
            </li>

          </ul>
          <el-button link @click="addLearningResume" class="w-100 addLearningResume" >
            <i class="fa-regular fa-circle-plus me-2"></i>
            <span>新增學習履歷</span></el-button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref="staticBackdrop">
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 mt-0" id="staticBackdropLabel">編輯學習履歷</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <el-form-item label="名稱">
          <el-input v-model="form.title" placeholder="請輸入履歷名稱" />
        </el-form-item>
        <el-form-item label="描述" class="mb-0">
          <el-input v-model="form.content" type="textarea"   placeholder="幫履歷新增描述吧"  />
        </el-form-item>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">關閉</button>
        <button type="button" class="btn btn-sm btn-primary" @click="editLearningResume()">儲存</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { Modal } from "bootstrap"
/* eslint-disable vue/no-unused-components */
import { Check, Close } from '@element-plus/icons-vue'

export default {
  components: {
    ckeditor: CKEditor.component,
    Check,Close
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
          'fontColor', 'fontBackgroundColor', // 加入文字顏色和背景顏色選項
          'undo', 'redo'
        ],
      },
      content:'',
      learningResumes:[
        {id:1,title:'13123',visible:true,content:'12312312234'},
        {id:2,title:'123',visible:false,content:'sdasdfsdfret45w'},
        {id:3,title:'123',visible:true,content:'gsdfgcvbhgj87j'}
      ],
      form:{},
      myModal: null  ,// 用於存儲模態對話框實例,
      editing_profile:false,
      editing_LearningResume:false
    }
  },
  computed: {
    c_currentUser() {
      return this.currentUser();
    },
  },
  methods: {
    //點擊編輯個人簡介
    editing_profile_click(){
      let vm =this;
      if(vm.editing_LearningResume==true){
        alert('學習履歷尚在編輯中，請先存檔') 
        return
      }
      vm.editing_profile = true
    },
    //點擊儲存個人簡介
    editing_profile_end_click(){
      let vm =this;
      vm.updateIntroduction();
    },
    //點擊編輯學習履歷
    editing_LearningResume_click(){
      let vm =this;
      if(vm.editing_profile==true){
        alert('個人簡介尚在編輯中，請先存檔') 
        return
      }
      vm.editing_LearningResume = true
    },
    //點擊儲存學習履歷
    editing_LearningResume_end_click(){
      let vm =this;
      vm.editing_LearningResume = false
    },
    //打開modal資料轉移
    open_editLearningResume(item) {
      let vm =this;
      vm.form =item
      if (vm.myModal) {
        vm.myModal.show();
      }
    },
    //新增學習履歷
    addLearningResume(){

      let vm =this;
      //axios
      let hasEmpty =  vm.learningResumes.filter(x=>x.title=='');
      if(hasEmpty.length >0){
        this.$message({
              type: "info",
              message: "列表已包含空白項目",
            });
            return
      }
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/Resume/add`,
        {
          title :'',
          content :'',
          visible :false
        }
      ).then(res=>{
        vm.learningResumes.push( {id:res.data.id ,title:'',visible:false,content:''});

      })
    },
    //更改學習履歷
    editLearningResume(){
      let vm =this;
      //axios
      vm.fetchAPI(
        "put",
        `${process.env.VUE_APP_URL_API}/Resume/edit`,
        {
          id :vm.form.id,
          title :vm.form.title,
          content :vm.form.content,
          visible :vm.form.visible,
        }
      ).then(res=>{
        if (vm.myModal && res.data.status ==0) {
        vm.myModal.hide();
      }
      })
    },
    //更改visible
    handleSwitchChange(i){
      let vm =this;
      vm.form =i;
      vm.editLearningResume();
    },  
    //更改學習履歷
    deleteLearningResume(id){
      
      let vm =this;
      this.$confirm(`確定要刪除此履歷嗎?`, "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
      //axios
        vm.fetchAPI(
          "delete",
          `${process.env.VUE_APP_URL_API}/Resume/delete/${id}`,
          {}
        ).then(res=>{
          if ( res.data.status ==0) {
            vm.learningResumes = vm.learningResumes.filter(x=>x.id != id)
          }
        })
      });
    
    },
    //更新簡介
    updateIntroduction(){
      let vm =this;
      const data = { 
        introduction:vm.content
      }
      vm.fetchAPI(
        "post",
        `${process.env.VUE_APP_URL_API}/Resume/updateIntroduction`,
        data
      ).then(res =>{
        console.log(res)
        vm.editing_profile = false;
      });
    },
    initData(){
      let vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/Resume/info`,
        {}
      ).then(res =>{
        vm.learningResumes = res.data.resumeInfos
        vm.content = res.data.introduction
      })
    },
    //回首頁
    goBack(){
      let vm = this
      vm.$router.push(`/ChargeMeTribe`);
    }
  },
  mounted() {
    let vm =this;
    const modalElement = this.$refs.staticBackdrop;
    if (modalElement) {
      this.myModal = new Modal(modalElement);
    }
    vm.initData();
  },
};
</script>

<style>
.editArea{
  background-color: #E6E6E6 !important;
  padding: 8px !important;
  margin:10px  0  ;
  display: inline-block;
  border-radius: 4px;
  font-weight: 600;
}
.learningResume_content{
  font-size: small;
  margin-top: 8px;
  color:#999999 !important;
}
.addLearningResume{
  color:blue !important
}
</style>